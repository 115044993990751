<script setup>
import Header from "./components/Header.vue";
import HeroSection from "./components/HeroSection.vue";
import NewsSection from "./components/NewsSection.vue";
import AssoSection from "./components/AssoSection.vue";
import DonateSection from "./components/DonateSection.vue";
import MarieCardsSection from "./components/MarieCardsSection.vue";
import ContactSection from "./components/ContactSection.vue";
import Footer from "./components/Footer.vue";

import { useTitle } from "@vueuse/core";
const title = useTitle("Onewink");
</script>

<template>
  <div class="content">
    <Header />
    <HeroSection />
    <NewsSection />
    <AssoSection />
    <DonateSection />
    <MarieCardsSection />
    <ContactSection />
    <Footer />
  </div>
</template>

<style>
*,
*::after,
*::before {
  box-sizing: border-box;
}

body {
  padding: 0;
  margin: 0;
  position: relative;
  height: auto;
  min-height: 100% !important;
  font-style: normal;
  font-size: calc(16px + 0.390625vw * 2);
  font-family: "Segoe UI", -apple-system, BlinkMacSystemFont, "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "little-days";
  src: url("./fonts/LittleDays.ttf") format("truetype");
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --lightgrey: hsla(0, 0%, 93%, 1);
  --grey: hsla(0, 0%, 60%, 1);
  --yellow: hsla(45, 100%, 50%, 1);
  --blue: hsla(203, 70%, 58%, 1);
  --black: hsla(0, 0%, 4.3%, 1);
}

h1 {
  text-transform: uppercase;
}

h2 {
  font-size: 1.5em;
  font-weight: normal;
}

h4 {
  font-weight: normal;
}

h6 {
  font-size: 1em;
  color: var(--lightgrey);
  font-weight: normal;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.spacer {
  height: 100px;
}

.section {
  padding-bottom: 4rem;
}

.callout__separator {
  background-color: var(--yellow);
  height: 10px;
  width: 100px;
  margin: 2rem 0;
  border-radius: 2px;
}
.cta-text {
  font-weight: bold;
  font-size: 1em;
  margin: 0 1rem;
  text-align: center;
}

button,
.cta-button {
  background-color: var(--yellow);
  padding: 1.2rem 2rem;
  border-radius: 10px;
  border-style: none;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 1em;
  color: var(--black);
}
</style>
