<script setup>
import { ref } from "vue";
import logo from "../images/brand-logo.png";

const navigationItems = ref([
  { label: "Home", link: "#hero", target: "" },
  { label: "News", link: "#news", target: "" },
  { label: "Association", link: "#about", target: "" },
  { label: "Faire un don", link: "#donate", target: "" },
  {
    label: "Shop",
    link: "https://shop.spreadshirt.ch/onewink/",
    target: "_blank",
  },
  { label: "Les cartes de Marie", link: "#marie", target: "" },
  { label: "Contact", link: "#contact", target: "" },
]);

const isMenuOpen = ref(false);

function toggleMenu() {
  isMenuOpen.value = !isMenuOpen.value;
}
</script>

<template>
  <header class="header">
    <div class="header__content">
      <a href="#hero">
        <div class="header__logo-container">
          <img :src="logo" alt="onewink logo" />
        </div>
      </a>
      <div class="header__main">
        <ul class="header__links">
          <li
            class="header__link-wrapper"
            v-for="(item, index) in navigationItems"
            :key="index"
          >
            <a
              class="header__link"
              :href="item.link"
              rel="noreferrer"
              :target="item.target"
            >
              {{ item.label }}
            </a>
          </li>
        </ul>
        <div class="header__sm-buttons">
          <img
            src="https://d33wubrfki0l68.cloudfront.net/79e75114856ae61628d2ad26504e3ff4ab2c71b6/f06a4/assets/svg/ham-menu.svg"
            alt="hamburger menu"
            class="header__main-ham-menu"
            :class="isMenuOpen ? 'd-none' : ''"
            @click="toggleMenu"
          />
          <img
            src="https://d33wubrfki0l68.cloudfront.net/de2a681c8ca1630b29949b3a34bf158a686a0554/6a0ec/assets/svg/ham-menu-close.svg"
            alt="hamburger menu close"
            class="header__main-ham-menu-close"
            :class="!isMenuOpen ? 'd-none' : ''"
            @click="toggleMenu"
          />
        </div>
      </div>
    </div>
    <div
      class="header__sm-menu"
      :class="isMenuOpen ? 'header__sm-menu--active' : ''"
    >
      <div class="header__sm-menu-content">
        <ul class="header__sm-menu-links">
          <li
            class="header__sm-menu-link"
            v-for="(item, index) in navigationItems"
            :key="index"
            @click="toggleMenu"
          >
            <a class="header__link" :href="item.link" :target="item.target">{{
              item.label
            }}</a>
          </li>
        </ul>
      </div>
    </div>
  </header>
</template>

<style>
.header {
  width: 100%;
  height: 100px;
  z-index: 1000;
  position: fixed;
  background: white;
  color: var(--black);
  box-shadow: 0 10px 100px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
}

.header__content {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin: 0 1rem;
}

.header__logo-container {
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
}

.header__logo-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.header__links {
  display: flex;
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
}

.header__link {
  padding: 1rem;
  display: inline-block;
  font-size: calc(16px + 0.2vw);
  text-transform: uppercase;
  font-weight: 500;
  color: var(--black);
  transition: color 0.3s;
}

.header__link:hover {
  color: var(--yellow);
}

.header__sm-buttons {
  display: none;
}

.d-none {
  display: none;
}

.header__sm-menu {
  background: #fff;
  position: absolute;
  width: 100%;
  top: 100%;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s;
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.1);
}

.header__sm-menu--active {
  visibility: hidden;
  opacity: 0;
}

.header__sm-menu-links {
  padding: 0;
  margin: 0;
}

.header__sm-menu-link a {
  display: block;
  text-decoration: none;
  padding: 1rem 2rem;
  color: var(--black);
  text-align: right;
  border-bottom: 1px solid #eee;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 2px;
  transition: color 0.3s;
}

.header__sm-menu-link:first-child a {
  border-top: 1px solid #eee;
}

@media only screen and (max-width: 1150px) {
  .header__sm-buttons {
    display: block;
  }

  .header__sm-menu--active {
    visibility: visible;
    opacity: 1;
  }

  .header__links {
    display: none;
  }

  .header__content {
    padding: 0.5em 1.2em;
  }

  .header__logo-img-cont {
    width: 4.5rem;
    height: 4.5rem;
    margin-right: 1.2rem;
  }

  .header__logo-span {
  }
}
</style>
