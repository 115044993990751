<script setup>
import { ref } from "vue";
import SectionTitle from "./SectionTitle.vue";
import pic_1 from "../images/onewink-thumb-up.jpg";
import pic_2 from "../images/onewink-medical.jpg";
import pic_3 from "../images/onewink-guys.png";
import profil_1 from "../images/onewink-danielmottet.jpg";
import profil_2 from "../images/onewink-melaniemottet.jpg";
import profil_3 from "../images/onewink-nathalievalgoi.jpg";
import profil_4 from "../images/onewink-claudiovalgoi.jpg";
const list = ref([
  {
    text: "L’association OneWink créée en 2016 apporte prioritairement son soutien à des familles dont un enfant est malade, hospitalisé ou en traitement de longue durée à la maison. Nous demeurons toutefois sensibles à toute situation difficile est imprévue à laquelle une personne, une famille, un enfant peuvent être confrontés. Nous sommes principalement actifs dans le Jura bernois et canton de Neuchâtel.",
    reverse: false,
    img: pic_2,
  },
  {
    text: "Notre 'outil' c'est le wink. C’est une action ponctuelle dans la vie d’une personne à travers laquelle nous apportons des bulles oxygénées d’espoir lors d’une maladie, d’un deuil, à la suite d’un accident, … Lorsque l’aide des services compétents existants n’est pas suffisante.",
    reverse: true,
    img: pic_1,
  },
  {
    text: "Nous avons déjà organisé plusieurs événements, tels que souper de soutien, course aux sponsors, vide-dressing, ventes diverses pour lever des fonds pour des causes particulières. Nous continuons à offrir des dons ponctuels, des cadeaux grâce à nos fidèles donateurs et aux personnes qui nous soutiennent par leurs activités.",
    reverse: false,
    img: pic_3,
  },
]);
const members = ref([
  { name: "Daniel Mottet", profil: profil_1 },
  { name: "Mélanie Mottet", profil: profil_2 },
  { name: "Nathalie Valgoï", profil: profil_3 },
  { name: "Claudio Valgoï", profil: profil_4 },
]);
</script>

<template>
  <div class="spacer" id="about"></div>
  <div class="assoc__container section">
    <SectionTitle
      title="Association"
      content="Apprenez en plus sur la raison d'être de notre association et rencontrez notre équipe."
    />
    <div class="assoc__content">
      <div
        v-for="item of list"
        class="assoc__sub-section"
        :class="{ reversed: item.reverse }"
      >
        <div class="assoc__sub-section_text">{{ item.text }}</div>
        <div class="assoc__sub-section_picture">
          <img :src="item.img" alt="onewink association" />
        </div>
      </div>
    </div>
    <div class="spacer" id="about"></div>
    <SectionTitle
      title="Notre équipe"
      content="Le comité fondateur de l'association."
    />
    <div class="team__content">
      <div v-for="item of members" class="team__members">
        <div class="team__profil">
          <img
            class="team__profil-pic"
            :src="item.profil"
            alt="onewink member"
          />
        </div>
        <div class="team__name">{{ item.name }}</div>
      </div>
    </div>
    <div class="team__question">
      <p class="cta-text">
        Vous souhaitez nous adresser une demande particulière?
      </p>
      <a class="cta-button" href="#contact">Contactez-nous</a>
    </div>
  </div>
</template>

<style>
.assoc__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.assoc__content {
  width: 90%;
  display: flex;
  flex-direction: column;
  background: var(--yellow);
}

.assoc__sub-section {
  display: flex;
}

.assoc__sub-section_text {
  width: 50%;
  height: 500px;
  padding: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-align: justify;
  text-justify: inter-word;
}

.assoc__sub-section_picture {
  width: 50%;
  height: 500px;
}

img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 100% 0;
}

.reversed {
  flex-direction: row-reverse;
}

.team__content {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.team__members {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.team__name {
  margin-top: 1rem;
  margin-bottom: 3rem;
  text-transform: uppercase;
}

.team__profil {
  width: 16rem;
  height: 16rem;
}

.team__profil-pic {
  border-radius: 10px;
}

.team__question {
  margin: 4rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

@media only screen and (max-width: 1000px) {
  .assoc__content {
    width: 100%;
    padding-bottom: 3rem;
  }

  .assoc__sub-section {
    flex-direction: column;
    align-items: center;
  }

  .assoc__sub-section_text {
    width: 90%;
    height: auto;
    padding: 3rem 0;
  }

  .assoc__sub-section_picture {
    width: 90%;
    height: auto;
  }

  img {
    object-fit: contain;
  }
}
</style>
