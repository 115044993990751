import { v4 as uuidv4 } from 'uuid'
import pic_1 from './onewink-website-lanch.png'
import pic_2 from './onewink-ukraine-2016.jpg'
import pic_3 from './onewink-vide-dressing-orvin.jpg'
import pic_4 from './onewink-vide-dressing-neuch.jpg'
import pic_5 from './onewink-course-sponsor.jpg'
import pic_6 from './onewink-course-sponsor-merci.jpg'
import pic_7 from './onewink-souper-lobby-bar.jpg'
import pic_8 from './onewink-expo-winkies.jpg'
import pic_9 from './onewink-actions-2019.jpg'
import pic_10 from './onewink-marie-cards-1.jpg'
import pic_11 from './onewink-teo.jpg'
import pic_12 from './onewink-florian.jpg'
import pic_13 from './onewink-sarah.jpg'
import pic_14 from './onewink-ukraine.jpg'
import pic_15 from './onewink-cafe-tramelan.jpg'
import pic_16 from './onewink-marie-cards-2.jpg'


const news = [
  {
    id: uuidv4(),
    date: "2023",
    title: "Les cartes de Marie",
    content: "Les cartes de Marie sont mises en vente au Magasin du Monde à Corgémont, au café partages à Tramelan et au Cadeaux fleuris à Courtelary. Vous trouverez tous les revendeurs sur le site de Marie.",
    img: pic_16
  },
  {
    id: uuidv4(),
    date: "2023",
    title: "Bon pour un séjour en famille",
    content: "Très touchés par la situation familiale d'une famille de la région, nous avons eu la joie de pouvoir offrir un bon pour des petites vacances en famille à une maman et ses 2 enfants. Nous leur souhaitons un WINK serein rempli de douceur et de sourires 👑🙏❤️",
  },
  {
    id: uuidv4(),
    date: "2022",
    title: "Bon pour une sortie en famille",
    content: "Nous avons eu à cœur d'offrir à un garçon du Jura bernois une journée au musée des transports à Lucerne à l'occasion de son anniversaire ! Il a pu s'y rendre en bus privé avec sa famille et ses amis. Ce garçon a eu beaucoup de traitements médicaux depuis son plus jeune âge et et vécu le décès de son papa il y a peu de temps. Nous sommes de tout cœur avec lui et sa maman dans ces temps difficiles 🫶❤️",
  },
  {
    id: uuidv4(),
    date: "Mai 2022",
    title: "Le café partages à Tramelan",
    content: "De nouveau beaucoup de belles choses au café partages. Cadeau de naissance, sarrouel, cartes de Marie sont disponibles à Tramelan. Les bénéfices de ces ventes sont reversés à l'association.",
    img: pic_15
  },
  {
    id: uuidv4(),
    date: "Mars 2022",
    title: "Soutien à des familles ukrainiennes",
    content: "Nous apportons une aide financière concrète pour des familles ukrainiennes et sommes de tout coeur avec tous les pays touchés par cette triste guerre.",
    img: pic_14
  },
  {
    id: uuidv4(),
    date: "Décembre 2021",
    title: "Le trophée du courage de SARAH",
    content: "Sarah est la soeur de Florian! Force à toi championne et à toute ta famille!",
    img: pic_13
  },
  {
    id: uuidv4(),
    date: "Décembre 2021",
    title: "Un trophée du COURAGE pour FLORIAN",
    content: "Quand une famille doit se battre médicalement pour un enfant, les frères et soeurs de l'enfant pris en charge par les médecins voient leur quotidien chamboulé du jour au lendemain. Par ce geste, l'association souhaite vous dire que vous êtes des vrais champions. On vous souhaite beaucoup de joie et de force au quotidien.",
    img: pic_12
  },
  {
    id: uuidv4(),
    date: "Octobre 2021",
    title: "Le premier trophée du COURAGE pour TEO",
    content: "Quand un dessin d'enfant se transforme en peluche! Un trophée pour un champion qui supporte avec force et courage une maladie rare.",
    img: pic_11
  },
  {
    id: uuidv4(),
    date: "Année 2020",
    title: "Nos actions 2020",
    content: "Grâce à votre fidélité et générosité nous avons pu soutenir 4 familles pour une somme totale de 2300.- Soutien à une famille dont la maman a fait un AVC, soutien à une famille dont une ferme à brûlée et soutien à des familles dont un enfant était malade. L'arrivée du COVID a stoppé les activités de l'association mais nous avons continuer de soutenir des familles par des dons ponctuels.",
  },
  {
    id: uuidv4(),
    date: "Année 2020",
    title: "Cartes de Marie - avec_amour_marie",
    content: "Un grand merci à Marie pour sa générosité. Elle offre à l'association un soutien régulier sur la vente des cartes qu'elle dessine:-) Découvrez sans plus tarder ses cartes sur notre page avec_amour_marie",
    img: pic_10
  },
  {
    id: uuidv4(),
    date: "Décembre 2019",
    title: "Bulle oxygénée 'détente'",
    content: "Pour clore l'année 2019 nous avons eu le plaisir d'offrir une bulle d'oxygène à une famille du vallon de St-Imier en lui offrant un séjour de 2 nuits dans un parc aquatique.",
  },
  {
    id: uuidv4(),
    date: "2019",
    title: "Nos actions",
    content: "",
    img: pic_9
  },
  {
    id: uuidv4(),
    date: "Octobre 2018",
    title: "Soutien à Naïa et sa famille",
    content: "Après une année de traitements intensifs, Naïa est en rémission. Nous avons eu à coeur d’offrir à cette famille un bon pour une sortie à Europa Park. Si vous avez à coeur de soutenir cette famille de la région de Portalban, vous pouvez envoyer un don via notre association en mentionnant Naïa lors de votre versement.",
  },
  {
    id: uuidv4(),
    date: "Septembre 2018",
    title: "Exposition et vente des premiers Winkies",
    content: "L’association crée des doudous, des hochets, des couvertures, …pour les vendre au Café-Partage à Tramelan. Les bénéfices sont reversés à l’association. Les tissus et fournitures diverses sont achetés grâce aux dons de nos donateurs réguliers. Si vous désirez faire un don pour du matériel, vous pouvez sans autre nous envoyer un courriel info@onewink.ch pour nous en informer. Nous désirons vivement pouvoir développer et investir de l’argent et du temps pour ces créations afin de pouvoir en offrir aux enfants que nous rencontrons grâce à l’association.",
    img: pic_8
  },
  {
    id: uuidv4(),
    date: "1er juin 2018",
    title: "Souper de soutien pour Samuel au Lobby Bar à Neuchâtel",
    content: "L’association a pu reverser 2500.- à la famille de Samuel pour un traitement de longue durée suite à une intervention chirurgicale du cerveau pour l’ablation d’une tumeur. Les 150 adultes et enfants présents à cette soirée ont offert à la famille de Samuel l’occasion de se sentir soutenus et aimés le temps d’une soirée par de multiples gestes de solidarités. Soutien qu’il ont aussi ressenti avant et après cet événement. Ensemble, continuons de renverser les lois de l’individualisme et de faire de la solidarité une force sans limite.",
    img: pic_7
  },
  {
    id: uuidv4(),
    date: "Février 2018",
    title: "Soutien à une maman de deux enfants",
    content: "L’association offre pour une valeur de 400.- des heures de baby-sitting à une maman seule devant assumer l’éducation de ses deux filles. OBJECTIF : se changer les idées, prendre du temps pour elle. Cette maman ne peut ni compter sur le papa, ni sur aucun membre de sa famille proche pour alléger les responsabilités familiales du quotidien et subit cette situation depuis des années. L’association soutient des familles dont un enfant est hospitalisé ou en traitement de longue durée mais aussi des familles confrontées à une situation difficile. Si un don de l’association permet à une personne de sortir la tête hors de l’eau un bref instant, notre objectif est atteint. OneWink fait des dons financiers mais offre aussi des bons qui répondent à une nécessité pour le bien-être familial. Grâce aux dons réguliers de généreux donateurs, nous pouvons offrir des « cadeaux »sous forme de bons. La reconnaissance exprimée en retour en vaut vraiment le coup. MERCI 😊",
  },
  {
    id: uuidv4(),
    date: "1er juillet 2017",
    title: "Course aux sponsors Orvin",
    content: "La course aux sponsors de samedi à Orvin a répondu à toutes nos attentes! MERCI à tous pour votre participation, votre présence et votre générosité!! Ensemble, renversons les lois de l individualisme et faisons de la SOLIDARITÉ une force sans limite:)!!",
    img: pic_6
  },
  {
    id: uuidv4(),
    date: "Avril 2017",
    title: "Course aux sponsors Orvin",
    content: "Nous travaillons actuellement à l’organisation d’une course aux sponsors qui se déroulera à Orvin. Elle est prévue le samedi 1 juillet 2017. Les fonds récoltés durant cette manifestation vont nous permettre de venir concrètement en aide à plusieurs familles de la région.",
    img: pic_5
  },
  {
    id: uuidv4(),
    date: "26 mars 2017",
    title: "Vide-dressing Neuchâtel",
    content: "Nous avons participé au vide-dressing Neuch’occase au Lobby bar à Neuchâtel.",
    img: pic_4
  },
  {
    id: uuidv4(),
    date: "28 janvier 2017",
    title: "Vide-dressing Orvin",
    content: "Nous avons participé au vide-dressing à Orvin. Les bénéfices nous ont permis de soutenir 2 familles d’Orvin en leur offrant 1 bon cadeau.",
    img: pic_3
  },
  {
    id: uuidv4(),
    date: "Décembre 2016",
    title: "Teaming",
    content: "Opération boules de neige avec l’enregistrement d’un groupe teaming.",
  },
  {
    id: uuidv4(),
    date: "Mai 2016",
    title: "Soutien voyage humanitaire",
    content: "Soutien d’un groupe de jeunes pour l’organisation d’une course aux sponsors dans le cadre d’une recherche de fonds pour leur voyage humanitaire en Moldavie.",
    img: pic_2
  },
  {
    id: uuidv4(),
    date: "Mars 2016",
    title: "Mise en ligne du site Internet",
    content: "Notre site a été publié sur Internet.",
    img: pic_1
  },
  {
    id: uuidv4(),
    date: "2016",
    title: "Création de l'association",
    content: "Tout a débuté lorsque nous avons réunis nos forces à notre volonté de vouloir amener un plus dans la société actuelle. Notre motivation vient de l’envie d’agir et d’apporter notre contribution à des familles dont un enfant est hospitalisé ou à la maison pour un traitement de longue durée.",
  },
]

export default news
