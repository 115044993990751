<script setup>
import logo from "../images/brand-logo-negatif.png";
import facebook from "../images/facebook.svg";
import instagram from "../images/instagram.svg";

const year = new Date().getFullYear(); // returns the current year
</script>

<template>
  <div class="footer__container">
    <div class="footer__main-cont">
      <div class="footer__main-logo">
        <img :src="logo" alt="onewink logo" />
      </div>
      <div class="footer__main-slogan">
        Investir dans l'<span>essentiel !</span>
      </div>
      <div class="footer__main-socials">
        <a
          href="https://www.facebook.com/OneWink-1521228331518785/"
          target="_blank"
        >
          <div class="footer__main-social">
            <img :src="facebook" class="svg" />
          </div>
        </a>
        <a
          href="https://www.instagram.com/onewink_association/"
          target="_blank"
        >
          <div class="footer__main-social">
            <img :src="instagram" class="svg" />
          </div>
        </a>
      </div>
    </div>
    <div class="footer_sub-footer-cont">
      © {{ year }} OneWink. Tous droits réservés.
    </div>
    <div class="footer_closing-footer-cont">
      Made by
      <a href="https://vjo-dev.onrender.com/" target="_blank">vjo-dev</a>
    </div>
  </div>
</template>

<style>
.footer__container {
  width: 100%;
}

.footer__main-cont {
  background: var(--black);
  color: white;
  padding: 4rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.footer__main-logo {
  margin: 2rem 0;
  width: 350px;
}

.footer__main-slogan {
  margin-bottom: 1.5rem;
}

.footer_sub-footer-cont {
  text-align: center;
  background: var(--yellow);
  color: var(--black);
  font-weight: 500;
  padding: 1.5rem 0;
  font-size: 0.6em;
}

.footer__main-slogan {
  text-transform: uppercase;
}

span {
  color: var(--yellow);
}

.footer__main-slogan {
  font-weight: 500;
  letter-spacing: 1px;
}

.footer__main-socials {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

.footer__main-social {
  height: 50px;
}

img {
  height: 100%;
}

svg {
  color: white;
}

.footer_closing-footer-cont {
  text-align: center;
  background: rgba(200, 200, 200, 0.7);
  color: var(--black);
  font-weight: bold;
  padding: 0.7rem 0;
  font-size: 0.5em;
}

.footer_closing-footer-cont a {
  text-decoration: underline;
  color: black;
}

@media only screen and (max-width: 1100px) {
  .footer__main-logo {
    width: 250px;
  }

  .footer__main-cont {
    padding: 2rem;
  }
}
</style>
