<script setup>
import emailjs from "@emailjs/browser";
import { ref } from "vue";

// fields
const namehjkl = ref("");
const emailhjkl = ref("");
const messagehjkl = ref("");
const isEmailInvalid = ref(false);
const isFormSent = ref(false);
// otherunusedfield
const name = ref("Steve");
const email = ref("");
const message = ref("");

// function to reset field error
function resetInvalid() {
  if (isEmailInvalid.value) {
    isEmailInvalid.value = !isEmailInvalid.value;
  }
}

// action to submit the form
function sendEmail() {
  // prevent with otherunusedfield
  if (name.value != "Steve" || email.value != "" || message.value != "") {
    console.log("bot detected");
    return;
  }

  // email string validation
  if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(emailhjkl.value)) {
    console.log("invalid email");
    isEmailInvalid.value = true;
    return;
  }

  // sent form
  try {
    emailjs
      .send(
        process.env.VUE_APP_EMAIL_SERVICE_ID,
        process.env.VUE_APP_EMAIL_SERVICE_TEMPLATE_ID,
        {
          name: namehjkl.value,
          email: emailhjkl.value,
          message: messagehjkl.value,
        },
        process.env.VUE_APP_EMAIL_SERVICE_PUB_KEY
      )
      .then(
        (result) => {
          console.log("SUCCESS!", result.text);
          resetForm();
        },
        (error) => {
          console.log("FAILED...", error.text);
          alert("ERROR: The form has not been sent!");
        }
      );
  } catch (err) {
    if (err instanceof ReferenceError) {
      alert("JSON Error: " + err.message);
    } else {
      throw err; // rethrow
    }
  }
  isFormSent.value = true;
}

function resetForm() {
  isFormSent.value = false;
  // Reset fields
  namehjkl.value = "";
  emailhjkl.value = "";
  messagehjkl.value = "";
  isEmailInvalid.value = false;

  // Reset otherunusedfield
  name.value = "Steve";
  email.value = "";
  message.value = "";
}
</script>

<template>
  <div class="container">
    <div
      class="confirmation"
      :style="isFormSent ? 'display:flex' : 'display:none'"
    >
      <h2>Message envoyé.</h2>
      <h1>Merci</h1>
      <button @click="resetForm">Ok</button>
    </div>
    <form v-on:submit.prevent="sendEmail">
      <!-- fields -->
      <label>Nom</label>
      <input
        type="text"
        v-model="namehjkl"
        name="namehjkl"
        placeholder="Votre nom"
        maxlength="100"
        required
      />
      <label>Email</label>
      <input
        :class="{ invalid: isEmailInvalid }"
        @click="resetInvalid"
        type="email"
        v-model="emailhjkl"
        name="emailhjkl"
        placeholder="Votre Email"
        required
      />
      <label>Message</label>
      <textarea
        name="messagehjkl"
        v-model="messagehjkl"
        cols="30"
        rows="5"
        placeholder="Message"
        required
      >
      </textarea>
      <input type="submit" value="Envoyer" />

      <!-- otherunusedfield -->
      <label class="fields">Name</label>
      <input
        class="fields"
        type="text"
        v-model="name"
        name="name"
        placeholder="Your Name"
        autocomplete="off"
        required
      />
      <label class="fields">Email</label>
      <input
        class="fields"
        type="email"
        v-model="email"
        name="email"
        placeholder="Your Email"
        autocomplete="off"
      />
      <label class="fields">Message</label>
      <textarea
        class="fields"
        name="message"
        v-model="message"
        cols="30"
        rows="5"
        placeholder="Message"
        autocomplete="off"
      >
      </textarea>
    </form>
  </div>
</template>

<style>
.fields {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  width: 0;
  z-index: -1;
}

.container {
  display: block;
  position: relative;
  margin: auto;
  text-align: center;
  border-radius: 10px;
  background-color: var(--lightgrey);
  padding: 2rem;
  width: 60%;
  border: solid 4px var(--grey);
}

.confirmation {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(200, 200, 200, 0.8);
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

label {
  float: left;
}

input[type="text"],
[type="email"],
textarea {
  width: 100%;
  padding: 0.5rem;
  border: 2px solid var(--grey);
  border-radius: 10px;
  box-sizing: border-box;
  margin-top: 1rem;
  margin-bottom: 2rem;
  resize: vertical;
  font-size: 1em;
  font-family: "Segoe UI";
  font-weight: 400;
  letter-spacing: 1px;
}

input[type="submit"]:hover {
  background-color: var(--black);
  color: white;
}

input[type="submit"] {
  background-color: var(--yellow);
  padding: 1.2rem 2rem;
  border-radius: 10px;
  border-style: none;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 1em;
  color: var(--black);
  cursor: pointer;
}

.invalid {
  border: solid 4px red;
}

@media only screen and (max-width: 1100px) {
  .container {
    width: 90%;
    padding: 1rem;
  }
}
</style>
