<script setup></script>

<template>
  <div class="spacer" id="hero"></div>
  <div
    class="hero__container section"
    :style="{
      backgroundImage: `url(${require('@/images/onewink-smiley.png')})`,
    }"
  >
    <div class="hero__content">
      <h1>SORTIR DE L'IMPASSE</h1>
      <div class="callout__separator"></div>
      <h2>
        Nous mobiliser pour sortir de l'impasse un enfant, une famille ou tout
        individu est pour nous, une <span>priorité</span> !
      </h2>
      <h6>
        OneWink reçoit des dons réguliers et ponctuels de la part de généreux
        donateurs. Vous voulez en faire partie?
      </h6>
      <a class="cta-button" href="#donate">faire un don</a>
    </div>
  </div>
</template>

<style>
.hero__container {
  width: 100%;
  min-height: calc(100vh - 100px);
  color: white;
  background-color: var(--black);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  text-align: center;
}

span {
  color: var(--yellow);
  font-weight: bold;
}

@media only screen and (max-width: 1100px) {
  .hero__content {
    width: 90%;
  }

  .hero__container {
    align-items: center;
  }
}
</style>
