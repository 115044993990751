<script setup>
import { ref } from "vue";
import SectionTitle from "./SectionTitle.vue";
import NewsItem from "./NewsItem.vue";
import news from "../data/news.js";
const count = ref(3);
function getShortList(size) {
  return news.slice(0, size);
}
</script>

<template>
  <div class="spacer" id="news"></div>
  <div class="news__container section">
    <SectionTitle
      title="News"
      content="Découvrez les dernières actions de l'association et les informations utiles."
    />
    <ul class="news__content-cont" v-if="news.length">
      <NewsItem
        v-for="item in getShortList(count)"
        :data="item"
        :key="item.id"
      ></NewsItem>
    </ul>
    <div v-else>Pas d'actualité pour le moment.</div>
    <div v-if="count < news.length">
      <button class="cta-button" @click="count = count + 3">Voir plus</button>
    </div>
  </div>
</template>

<style>
.news__container {
  min-height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.news__content-cont {
  width: 80%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 600px) {
  .news__content-cont {
    width: 90%;
  }
}
</style>
